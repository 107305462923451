<template>
    <div class="layout">
        <el-container>
            <!-- 左侧导航栏 -->
            <el-aside :width="isCollapsed ? '64px' : '200px'" class="aside">
                <el-menu background-color="#2C3E50" text-color="#FFFFFF" active-text-color="#409EFF"
                    :collapse="isCollapsed" unique-opened router>
                    <el-submenu>
                        <template slot="title">
                            <i class="el-icon-user"></i>
                            <span v-if="!isCollapsed && authInfo != null">{{ authInfo.userName }}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="/info">信息</el-menu-item>
                        </el-menu-item-group>
                        <el-menu-item-group>
                            <el-menu-item @click="logout">退出</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <template v-for="(route, index) in routes">
                        <el-submenu :key="route.name" v-if="route.inNav && checkPermission(route.permission)"
                            :index="index">
                            <template slot="title">
                                <i v-if="route.icon != null" :class="route.icon"></i>
                                <i v-else class="el-icon-menu"></i>
                                <span v-if="!isCollapsed">{{ route.name }}</span>
                            </template>
                            <el-menu-item-group v-for="cItem in route.children" :key="cItem.name">
                                <el-menu-item :index="cItem.path">{{ cItem.name }}</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </template>
                </el-menu>

            </el-aside>

            <!-- 右侧内容 -->
            <el-main class="main">
                <router-view />
            </el-main>
        </el-container>
    </div>
</template>

<script>
import routes from "../router/routes";
import { checkPermission } from './auth/AuthInfo'
export default {

    data() {
        return {
            routes,
            isCollapsed: false, // 控制菜单展开/收起状态
        };
    },
    methods: {
        handleResize() {
            // 根据窗口宽度调整导航栏展开/收起状态
            this.isCollapsed = window.innerWidth < 768;
        },
        logout() {
            this.$store.dispatch("auth/logout");
        },
        
        checkPermission
    },

    mounted() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    created() {
        this.$store.dispatch("auth/loadAuthInfo");
    },
    computed: {
        authInfo: function () {
            return this.$store.state.auth.authInfo;
        },
    },

};
</script>

<style scoped>
.layout {
    height: 100vh;
    /* 整个布局高度固定为屏幕高度 */
}

.aside {
    height: 100vh;
    background-color: #2C3E50;
    /* 商务风格深蓝色 */
    color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s;
    /* 添加过渡效果 */
}

.main {
    height: 100vh;
    /* background-color: #f5f5f5; */
    overflow-y: auto;
    /* 如果内容超过可视区域，启用滚动 */
}


.el-menu {
    height: 100%;
    /* 让菜单填满侧边栏的高度 */
    border-right: none;
    /* 去掉默认的右边框 */
}
</style>